import React from 'react'
import Link from 'gatsby-link'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDBMask, MDBRow, MDBCol,MDBView, MDBContainer, MDBBtn } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight, faMessageMiddle } from '@fortawesome/pro-regular-svg-icons'
import Tilt from 'react-tilt'

class Hero extends React.Component {
  handleClick(value) {
    typeof window !== 'undefined' &&
      document.querySelector(value).scrollIntoView({
        behavior: 'smooth',
      })
  }
  render() {
    return (
      <section className={this.props.class}>
        <MDBView>
          <MDBMask
            className={
              this.props.gradientClass != null
                ? this.props.gradientClass
                : 'mask d-flex justify-content-center align-items-center gradient'
            }
          >
            <MDBContainer>
              <MDBRow>
                {this.props.type === 'home' || this.props.type === '404' ? (
                  <>
                    <MDBCol
                      lg="6"
                      className="my-auto text-lg-left text-center text-white"
                    >
                        <h1
                          className="font-alt font-w-700 text-white title-xs-extra-large title-extra-large-1 mb-5 mb-xl-5"
                          dangerouslySetInnerHTML={{ __html: this.props.title }}
                        />
                        <h2
                          className="font-alt font-w-400 mb-5 title-xs-small title-small letter-spacing-1"
                          dangerouslySetInnerHTML={{
                            __html: this.props.subtitle,
                          }}
                        />
                        {this.props.ctaslug && (
                            <MDBBtn to={this.props.ctaslug} offset={-150} className="nav-link btn btn-hero-color mr-0 mt-xl-2 btn-w-245 hero-btn1"
                              onClick={() =>
                                this.handleClick(this.props.ctaslug)
                              }
                            >
                              <FontAwesomeIcon icon={faChevronDown} className="mr-2" />
                              {this.props.ctatext ? this.props.ctatext : 'Explore'}
                            </MDBBtn>
                          )}
                          {this.props.ctaslug2 && ( 
                            <>
                            {this.props.ctaslug2.substring(0,4) === 'http' ? 
                            <a href={this.props.ctaslug2} target="_blank" className="nav-link btn btn-mdb-color mr-0 mt-xl-2 btn-mdb-border btn-w-245 hero-btn2">
                              <FontAwesomeIcon icon={faMessageMiddle} className="mr-2" />
                              {this.props.ctatext2 ? this.props.ctatext2 : 'Schedule a consultation'}
                            </a>
                            :
                            <Link to={this.props.ctaslug2} className="nav-link btn btn-mdb-color mr-0 mt-xl-2 btn-mdb-border btn-w-245 hero-btn2">
                              <FontAwesomeIcon icon={faMessageMiddle} className="mr-2" />
                              {this.props.ctatext2 ? this.props.ctatext2 : 'Schedule a consultation'}
                            </Link>
                            }
                            </>
                          )}
                    </MDBCol>
                  </>
                ) : this.props.type === 'news' ? (
                  <>
                    <h1
                      className="font-alt font-w-700 text-left text-md-left text-white title-xs-medium title-large-3 mb-xl-2 mx-3"
                      dangerouslySetInnerHTML={{ __html: this.props.title }}
                    />
                    {this.props.subtitle != '' && this.props.subtitle != null && (
                      <h2
                        className="text-white font-w-700 font-alt mb-4 title-xs-medium mx-3 letter-spacing-1"
                        dangerouslySetInnerHTML={{
                          __html: this.props.subtitle,
                        }}
                      />
                    )}

                    {this.props.ctaslug != '' && this.props.ctaslug != null && (
                      <Link
                        to={this.props.ctaslug}
                        className="nav-link btn btn-hero-color mr-0 mt-xl-3 ml-3"
                      >
                        <FontAwesomeIcon icon={faChevronRight} />{' '}
                        {this.props.ctatext != '' && this.props.ctatext != null
                          ? this.props.ctatext
                          : 'Read'}
                      </Link>
                    )}
                  </>
                ) : (
                  <div className="white-text text-center col-md-12 mt-xl-2">
                    <h1
                      className="font-alt font-w-700 text-yellow title-xs-extra-large title-large-3 mt-sm-5 mb-xl-4"
                      dangerouslySetInnerHTML={{ __html: this.props.title }}
                    />
                    {this.props.subtitle != '' && this.props.subtitle != null && (
                      <h2
                        className="text-white font-alt font-w-700 mb-4 title-xs-medium letter-spacing-1"
                        dangerouslySetInnerHTML={{
                          __html: this.props.subtitle,
                        }}
                      />
                    )}

                {this.props.ctaslug != '' && this.props.ctaslug != null && (
                      <Link to={this.props.ctaslug} className="nav-link btn btn-mdb-color mr-0 mt-xl-3" >
                        <FontAwesomeIcon icon={faChevronRight} />{' '}
                        {this.props.ctatext != '' && this.props.ctatext != null
                          ? this.props.ctatext
                          : 'Read'}
                      </Link>
                    )}
                  </div>
                )}

              {typeof window !== 'undefined' && !/Android|webOS|iPhone|iPod|BlackBerry|BB|IEMobile|Windows Phone|Silk|Opera Mini/i.test( navigator.userAgent ) && (    
                  <MDBCol lg="6" className="mt-5 mt-lg-0 pl-lg-5 hero-image">
                    <div className="d-none d-lg-block">
                      <Tilt
                        className="Tilt"
                        options={{ max: 25 }} 
                        // style={{ height: 549, width: 500 }}
                      >
                        {/* <GatsbyImage
                          image={this.props.foreimage}
                          className="img-fluid d-none d-lg-block"
                          alt={this.props.forealt}
                          // style={{ zIndex: '999' }}
                        /> */}
                        <img src={this.props.foreimage.images.fallback.src} loading="eager" alt={this.props.forealt} />
                      </Tilt>
                    </div>
                  </MDBCol>
                )}
              </MDBRow>
            </MDBContainer>
            <div id="scrollto">&nbsp;</div>
          </MDBMask>
        </MDBView>
      </section>
    )
  }
}

export default Hero
